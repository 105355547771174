import SingleProjectToDelete from "../SingleProjectToDelete";
import BlueBox from "../BlueBox";
import CloseIcon from '@mui/icons-material/Close';
import GoldLineSeparator from '../UI/GoldLineSeparator'
import BaroqueSeparator from '../UI/BaroqueSeparator';
import Button from "../UI/Button";

import { useEffect, useState, useContext} from "react";
import dataContext from '../../store/dataContext';
import styles from './deleteProjectForm.module.css';


const DeleteProjectForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const [formResponse, setFormResponse] = useState('');
    const dataCtx = useContext(dataContext);
    const [projekte, setProjekte] = useState([{}]);


    const getAllProjects = () => { 
        console.log('Getting Projects');
        fetch(`${API_URL}/controller/projectTasks.php`, {
        method: 'GET'
          })
          .then(response => response.json())
          .then(allData => {
            if(allData) {
              dataCtx.getProjects(allData.data);
              setProjekte(dataCtx.projects);
            }
           })};

 
    useEffect(() => {
        
    getAllProjects();

    }, []);
        
    

        const onProjectDeleteHandler = (id) => {
        const body = "projectid=" + id;
        fetch (`${API_URL}/controller/projectTasks.php?${body}`,
        {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(response.messages[0])})
        .then(allData => {setFormResponse(allData.messages[0]); getAllProjects()})
        .catch(error => setFormResponse(error));


            
    }


    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

        
              

        <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

        <BlueBox padding='40px' width= '60%' height= '80%'  overflow='scroll'>
                <h2 className={styles.titel}>Project löschen</h2>
                <GoldLineSeparator />

                <BaroqueSeparator />
            

                {dataCtx.projects.map(
                    project => { 
                        return(<div className={styles.singleConcertContainer}>
                            <SingleProjectToDelete 
                                    key = {Math.random()}
                                    titel={project.projectTitle}/>
                                    <button key={Math.random()} className={styles.button} onClick = {() => {onProjectDeleteHandler(project.id)}}>Löschen</button>
                        </div>
                                )
                            }
                        )
                    }

                   

                <Button onClickFunction = {props.onModalClose}>Schließen</Button>

        </BlueBox>


        
            <p className={styles.response}>{formResponse}</p>
        
        
    </div>
    )
}

export default DeleteProjectForm;