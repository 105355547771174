import styles from './singleConcert.module.css';

const SingleConcert = (props) => {
    return (
        <div className={styles.concert}>
            <h5 className={styles.datum}>{props.datum}</h5>
            <p className={styles.titel}>{props.titel}</p>
            <p className={styles.ort}>{props.ort}</p>
            <p className={styles.ensemble}>{props.ensemble}</p>
            <p className={styles.stadt}>{props.stadt}</p>
            
        </div>
    )
}

export default SingleConcert;