import styles from './button.module.css';
import { useState } from 'react';

const Button = (props) => {

    const [isHovering, setIsHovering] = useState(false);

    const hoveringOn = () => {
        setIsHovering(true);
        
    }

    const hoveringOff= () => {
        setIsHovering(false);
    }

    const animationBarActive1 = (isHovering || props.viewport <= 1024) ? styles.animationBarActivated1 : styles.animationBar1;
    const animationBarActive2 = (isHovering || props.viewport <= 1024) ? styles.animationBarActivated2 : styles.animationBar2;
    const animationBarActive3 = (isHovering || props.viewport <= 1024) ? styles.animationBarActivated3 : styles.animationBar3;
    const animationBarActive4 = (isHovering || props.viewport <= 1024) ? styles.animationBarActivated4 : styles.animationBar4;
    const butt = isHovering ? styles.buttonHover : styles.button


    return(
        <div className={styles.buttonBox} style={{width: props.width}}>
            <span className={animationBarActive1}></span>
            <span className={animationBarActive2}></span>
            <span className={animationBarActive3}></span>
            <span className={animationBarActive4}></span>
        <button onMouseEnter={hoveringOn} onMouseLeave={hoveringOff} className={butt} 
                onClick = {props.onClickFunction} 
                type={props.buttonType} 
                style={{height: props.height, width: props.width, margin: props.margin}}
                >{props.children}
                </button>
        </div>
    )
}

export default Button;