import styles from './goldLineSeparator.module.css';

const GoldLineSeparator = (props) => {
    return (
        <div style={{width: '100%'}}>
                <hr style={{width: props.width}}className={styles.separation_line_horizontal}/>
            </div>
    )
}

export default GoldLineSeparator;
