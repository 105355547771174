import SingleConcertToDelete from "../SingleConcertToDelete";
import BlueBox from "../BlueBox";
import CloseIcon from '@mui/icons-material/Close';
import GoldLineSeparator from '../UI/GoldLineSeparator'
import BaroqueSeparator from '../UI/BaroqueSeparator';
import Button from "../UI/Button";

import { useEffect, useState, useContext} from "react";
import dataContext from '../../store/dataContext';
import styles from './deleteTerminForm.module.css';


const DeleteTerminForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const dataCtx = useContext(dataContext);

    const [formResponse, setFormResponse] = useState('');

    const getAllConcerts = () => { fetch(`${API_URL}/controller/terminTasks.php`, {
        method: 'GET'
          })
          .then(response => response.json())
          .then(allData => {
            if(allData) {
              dataCtx.getOldConcerts(allData.data[1]);
              dataCtx.getNewConcerts(allData.data[0]);
            }
           })};

 
    useEffect(() => {
        
    getAllConcerts();

    }, []);
        
    

        const onConcertDeleteHandler = (id) => {
        const body = "termin_id=" + id;
        fetch (`${API_URL}/controller/terminTasks.php?${body}`,
        {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(response.messages[0])})
        .then(allData => {setFormResponse(allData.messages[0]); getAllConcerts()})
        .catch(error => setFormResponse(error))
            
    }


    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

        
              

        <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

        <BlueBox padding='40px' width= '60%' height= '80%'  overflow='scroll'>
                <h2 className={styles.titel}>Termin löschen</h2>
                <GoldLineSeparator />

                <BaroqueSeparator />
            

                {dataCtx.oldConcerts.reverse().map(
                    oldconcert => { 
                        return(<div className={styles.singleConcertContainer}>
                            <SingleConcertToDelete 
                                    key = {Math.random()}
                                    datum={oldconcert.datum}
                                    titel={oldconcert.titel}/>
                                    <button key={oldconcert.id} className={styles.button} onClick = {() => {onConcertDeleteHandler(oldconcert.id)}}>Löschen</button>
                        </div>
                                )
                            }
                        )
                    }

                {dataCtx.newConcerts.map(
                    newconcert => { 
                        return(<div className={styles.singleConcertContainer}>
                            <SingleConcertToDelete
                                    key = {Math.random()}
                                    datum={newconcert.datum}
                                    titel={newconcert.titel}/>
                                    <button key={newconcert.id} className={styles.button} onClick = {() => {onConcertDeleteHandler(newconcert.id)}}>Löschen</button>
                        </div>

                                )
                            }
                        )
                    }   

                <Button onClickFunction = {props.onModalClose}>Schließen</Button>

        </BlueBox>


        
            <p className={styles.response}>{formResponse}</p>
        
        
    </div>
    )
}

export default DeleteTerminForm;