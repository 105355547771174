import { useContext, useEffect } from "react";
import dataContext from '../store/dataContext';


export const useConcerts = () => {

  const API_URL = process.env.REACT_APP_API_URL;

    const dataCtx = useContext(dataContext);

 
  useEffect(() => {
    const getAllConcerts = () => { fetch(`${API_URL}/controller/terminTasks.php`, {
    method: 'GET'
      })
      .then(response => response.json())
      .then(allData => {
        if(allData) {
          dataCtx.getOldConcerts(allData.data[1]);
          dataCtx.getNewConcerts(allData.data[0]);
        }
        else alert('no Data');
       })};
    getAllConcerts()}, []);

    return dataCtx;
    
}