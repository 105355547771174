import styles from './blueLineSeparator.module.css';

const BlueLineSeparator = (props) => {
    return (
        <div style={{width: '100%'}}>
                <hr style={{width: props.width}} className={styles.separation_line_horizontal}/>
            </div>
    )
}

export default BlueLineSeparator;
