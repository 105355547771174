import { useContext, useState, Fragment} from "react";
import authContext from "../store/authContext";
import LoginForm from "../components/LoginForm";
import Button from "../components/UI/Button";
import BlueBox from "../components/BlueBox";
import ReactDOM from 'react-dom';
import Backdrop from "../components/Backdrop";
import AddTerminForm from "../components/admin/AddTerminForm";
import DeleteTerminForm from "../components/admin/DeleteTerminForm";
import AddNewsForm from "../components/admin/AddNewsForm";
import DeleteNewsForm from "../components/admin/DeleteNewsForm";
import AddProjectForm from "../components/admin/AddProjectForm";
import DeleteProjectForm from "../components/admin/DeleteProjectForm";
import { useNavigate } from "react-router-dom";
import { useLoginCheck } from "../customHooks/useLoginCheck";

import styles from './admin.module.css';



const Admin = () => {

    useLoginCheck();   

    const navigate = useNavigate();
    const backdropPortal = document.getElementById('backdrop');

    const modal = document.getElementById('modal');

    const [adminModalsAreOn, setAdminModalsAreOn] = useState('off')

    const authCtx = useContext(authContext);

    const isLoggedIn = authCtx.isLoggedIn;

    const onLoginModalCloseHandler = () => {
        navigate('/');
    }

    const onShowTerminFormHandler = () => {
        setAdminModalsAreOn('addTermin');
    }

    const onShowDeleteTerminFormHandler = () => {
        setAdminModalsAreOn('deleteTermin');
    }

    const onCloseFormHandler = () => {
        setAdminModalsAreOn('off');
    }

    const onShowAddNewsFormHandler = () => {
        setAdminModalsAreOn('addNews');
    }

    const onShowDeleteNewsFormHandler = () => {
        setAdminModalsAreOn('deleteNews');
    }

    const onShowAddProjectFormHandler = () => {
        setAdminModalsAreOn('addProject');
    }

    const onShowDeleteProjectFormHandler = () => {
        setAdminModalsAreOn('deleteProject');
    }

    return(
        <Fragment>
        {isLoggedIn && (
            
        <main>
        <h1>Admin-Bereich</h1>
        {(adminModalsAreOn === 'addTermin') && 
        ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {(adminModalsAreOn === 'addTermin') && 
        ReactDOM.createPortal(<AddTerminForm onModalClose={onCloseFormHandler}/>, modal)}
        {(adminModalsAreOn === 'deleteTermin') && 
        ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {(adminModalsAreOn === 'deleteTermin') && 
        ReactDOM.createPortal(<DeleteTerminForm onModalClose={onCloseFormHandler}/>, modal)}
        {(adminModalsAreOn === 'addNews') && 
        ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {(adminModalsAreOn === 'addNews') && 
        ReactDOM.createPortal(<AddNewsForm onModalClose={onCloseFormHandler}/>, modal)}
        {(adminModalsAreOn === 'deleteNews') && 
        ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {(adminModalsAreOn === 'deleteNews') && 
        ReactDOM.createPortal(<DeleteNewsForm onModalClose={onCloseFormHandler}/>, modal)}
        {(adminModalsAreOn === 'addProject') && 
        ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {(adminModalsAreOn === 'addProject') && 
        ReactDOM.createPortal(<AddProjectForm onModalClose={onCloseFormHandler}/>, modal)}
        {(adminModalsAreOn === 'deleteProject') && 
        ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {(adminModalsAreOn === 'deleteProject') && 
        ReactDOM.createPortal(<DeleteProjectForm onModalClose={onCloseFormHandler}/>, modal)}
        <BlueBox display='flex' flexDirection='row' justifyContent='space-around' alignItems='center' margin='40px auto 0 auto' width='90%' height='400px' flexWrap='wrap' overflow='auto'>
        
        <div className={styles.buttonBox}>
        <Button width='300px' onClickFunction={onShowTerminFormHandler}>Termin eintragen</Button>
        <Button width='300px' onClickFunction={onShowDeleteTerminFormHandler}>Termin löschen</Button>
        </div>
        <div className={styles.buttonBox}>
        <Button width='300px' onClickFunction={onShowAddNewsFormHandler}>News eintragen</Button>
        <Button width='300px' onClickFunction={onShowDeleteNewsFormHandler}>News löschen</Button>
        </div>

        <div className={styles.buttonBox}>
        <Button width='300px' onClickFunction={onShowAddProjectFormHandler}>Projekt eintragen</Button>
        <Button width='300px' onClickFunction={onShowDeleteProjectFormHandler}>Projekt löschen</Button>
        </div>
        </BlueBox>
        </main>
        
        )}
        {!isLoggedIn && ReactDOM.createPortal(<Backdrop />, backdropPortal)}
        {!isLoggedIn && ReactDOM.createPortal(<LoginForm onModalClose={onLoginModalCloseHandler}/>, modal)}
        </Fragment>
    )
}

export default Admin;