import styles from './newsModal.module.css';

import { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import BaroqueSeparator from './UI/BaroqueSeparator';
import GoldLineSeparator from './UI/GoldLineSeparator';
import Button from './UI/Button';
import BlueBox from './BlueBox';
import WhiteLineSeparator from './UI/WhiteLineSeparator';


const NewsModal = (props) => {

    const body = document.getElementById('root');

    useEffect (() => {
            body.classList.toggle('scroll_blocked');
    }, [props.isOn])
   

    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>
            
            <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>
        <div className={props.isOn ? styles.linksBox : styles.linksBoxClosing}>
            

            <BlueBox padding='5%' margin='0' width= '100%' overflow='scroll' height='100vh'>
            <h1 className = {styles.title}>Letzte News</h1>
            <GoldLineSeparator width= '60%' />
            <BaroqueSeparator />
            <div className={styles.lastNewsWrapper}>
            <img src={props.image} className={styles.lastNewsBild} />
                    <div className={styles.lastNewsItem}>
                        <h3 className='white'>{props.title}</h3>
                        <WhiteLineSeparator width='50%'/>
                        <p className={styles.projectText}>{props.content ? props.content : ''}</p>
                        
                        <Button onClickFunction={props.onModalClose}>zu Home</Button>
                    </div>
            </div>
            </BlueBox>

            </div>

            
        </div>
    )
}

export default NewsModal;