import styles from './contactForm.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from './UI/Button';


import { useRef, useState } from 'react';

const ContactForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;
    
    const inputVornameRef = useRef();
    const inputNameRef = useRef();
    const inputEmailRef = useRef();
    const inputNachrichtRef = useRef();

    const [formResponse, setFormResponse] = useState('');


    const onSubmitHandler = (event) => {
        event.preventDefault();
        const vorname = inputVornameRef.current.value;
        const name = inputNameRef.current.value;
        const email = inputEmailRef.current.value;
        const nachricht = inputNachrichtRef.current.value;

        fetch(`${API_URL}/controller/mailer.php`,{
        method: 'POST',
        body: "vorname=" + vorname + "&name=" + name + "&email=" + email + "&nachricht=" + nachricht,
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
        })
        .then(response => {
            if(response.ok) return response.json()
            else throw new Error('Server-Verbindungsprobleme. Bitte später erneut versuchen!');})
        .then(responseData => {
            if(responseData) setFormResponse(responseData.messages[0]);
            })
        .catch(error =>setFormResponse(error));
    }

    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

            <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>
      
                <form className = {props.isOn ? styles.form : styles.formClosing} onSubmit={onSubmitHandler}>
                <h1 className={styles.title}>KONTAKTFORMULAR</h1>
                <label htmlFor='vorname' className = {styles.label}>Ihr Vorname</label>
                <input ref={inputVornameRef} name = 'vorname' type='text' className = {styles.input} />
                <label htmlFor='name' className = {styles.label}>Ihr Name</label>
                <input ref={inputNameRef} name = 'name' type='text' className = {styles.input} />
                <label htmlFor='email' className = {styles.label}>Ihre Email</label>
                <input ref={inputEmailRef} name = 'vormane' type='email' className = {styles.input} required />
                <label htmlFor='nachricht' className = {styles.label}>Ihre Nachricht</label>
                <textarea ref={inputNachrichtRef} className={styles.message}></textarea>
                <div className={styles.buttons}>
                <Button buttonType="submit">Absenden</Button>
                <Button onClickFunction={props.onModalClose} buttonType="reset">Schließen</Button>
                </div>
                <p className='white'>{formResponse}</p>
            </form>
          
            
        </div>
    )
}

export default ContactForm;