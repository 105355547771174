import { PropaneSharp } from '@mui/icons-material';
import { useState } from 'react';

import styles from './burgerMenu.module.css';


const BurgerMenu = (props) => {

    

    return(
    <div className={`${styles.nav_icon1}  ${props.isOn ? styles.open : ''}`} onClick={props.onTrigger}>
  <span></span>
  <span></span>
  <span></span>
        </div>
)
}

export default BurgerMenu;
