import './sliderMenu.css';
import { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import BlueBox from "../BlueBox";
import { CSSTransition } from 'react-transition-group';

const SliderMenu = (props) => {

const body = document.getElementById('root');

useEffect (() => {
    if(props.isOn) {
        body.classList.toggle('scroll_blocked');
    }
}, [props.isOn])


    return(
        <CSSTransition in={props.isOn} timeout={400} classNames='sliderMenu' mountOnEnter unmountOnExit>
        <article className='sliderMenu'>
            <ul>
                <li onClick={props.onTrigger}><NavLink to='home' className='sliderLink'>Home</NavLink></li>
                <li onClick={props.onTrigger}><NavLink to='bio' className='sliderLink'>Bio</NavLink></li>
                <li onClick={props.onTrigger}><NavLink to='concerts' className='sliderLink'>Concerts</NavLink ></li>
                <li onClick={props.onTrigger}><NavLink to='media' className='sliderLink'>Media</NavLink ></li>
             </ul>
        </article>
        </CSSTransition>

    )
}

export default SliderMenu;