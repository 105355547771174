

import styles from './concertBoxNavigator.module.css';
import ArrowBackIconRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardIconRounded from '@mui/icons-material/ArrowForwardRounded';

const ConcertBoxNavigator = (props) => {

    const setActiveConcertItem = (item) => {
        props.onSwitch(item);
    }

    return(

            <div className={styles.container}>
                {(props.index > 1) && <ArrowBackIconRounded fontSize='small' className={styles.arrowBack}
                onClick = {() => {setActiveConcertItem(props.index-1)}} /> }
                <span className={styles.navigationNumbersActive}>{props.index}</span>
                {(props.length >= (props.index +1)) && <span className={styles.navigationNumbers} onClick = {() => {setActiveConcertItem(props.index+1)}}>{props.index +1}</span>}
                {(props.length >= (props.index +2)) && <span className={styles.navigationNumbers} onClick = {() => {setActiveConcertItem(props.index+2)}}>{props.index +2}</span>}
                {(props.length >= (props.index +3)) && <ArrowForwardIconRounded fontSize='small' className={styles.arrowForward} onClick = {() => {setActiveConcertItem(props.index+1)}}/>}
            </div>


    )
}

export default ConcertBoxNavigator;