import styles from './addNewsForm.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../UI/Button';

import { useRef, useState } from 'react';

const AddNewsForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const inputNewsTitleRef = useRef();
    const inputNewsTextRef = useRef();
    const inputImageFileRef = useRef();


    const [formResponse, setFormResponse] = useState('');
    const [imageFile, setImageFile] = useState();

    const onImageFileInputHandler = (event) => {
        setImageFile(event.target.files[0]);
        
    }


    const onSubmitHandler = (event) => {
        event.preventDefault();
     
        
        const newsTitle = inputNewsTitleRef.current.value;
        const newsText = inputNewsTextRef.current.value;
        console.log(newsTitle, newsText, imageFile);
        const formData = new FormData();
        formData.append('newsTitle', newsTitle);
        formData.append('newsText', newsText);
        formData.append('photo', imageFile);


      

        fetch(`${API_URL}/controller/newsTasks.php`,
        {method: 'POST',
        body: formData
        })
        .then(response => {
            if(response.ok) return response.json()
            else throw new Error('Server-Verbindungsprobleme. Bitte später erneut versuchen!');})
        .then(responseData => {
            if(responseData) setFormResponse(responseData.messages[0]);
            })
        .catch(error =>setFormResponse(error));
    }

    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

            <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

            <form className = {styles.form} onSubmit={onSubmitHandler}>
                <h1 className={styles.title}>News hinzufügen</h1>
                <label htmlFor='newsTitle' className = {styles.label}>News-Titel</label>
                <input ref={inputNewsTitleRef} name = 'newsTitle' type='text' className = {styles.input} />
                <label htmlFor='newsText' className = {styles.label}>News-Text</label>
                <textarea ref={inputNewsTextRef} name='newsText' className={styles.message}></textarea>
                <label htmlFor='image' className = {styles.label}>Bild-Datei</label>
                <input ref={inputImageFileRef} onChange = {onImageFileInputHandler} name='image' type='file' className = {styles.fileUpload} accept="image/png, image/jpg, image/gif, image/jpeg" required />
                <div className={styles.buttons}><Button buttonType="submit">Posten</Button>
                <Button onClickFunction={props.onModalClose}>Schließen</Button>
                </div>
                <p className='white'>{formResponse}</p>
            </form>
            
        </div>
    )
}

export default AddNewsForm;