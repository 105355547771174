import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './linkButton.module.css';

const LinkButton = (props) => {

    const [isHovering, setIsHovering] = useState(false);

    const hoveringOn = () => {
        setIsHovering(true);
        
    }

    const hoveringOff= () => {
        setIsHovering(false);
    }

    const animationBarActive1 = isHovering ? styles.animationBarActivated1 : styles.animationBar1;
    const animationBarActive2 = isHovering ? styles.animationBarActivated2 : styles.animationBar2;
    const animationBarActive3 = isHovering ? styles.animationBarActivated3 : styles.animationBar3;
    const animationBarActive4 = isHovering ? styles.animationBarActivated4 : styles.animationBar4;
    const linkText = isHovering ? styles.linkTextHover : styles.linkText


    return(

        <div className={styles.buttonBox}>
            <span className={animationBarActive1}></span>
            <span className={animationBarActive2}></span>
            <span className={animationBarActive3}></span>
            <span className={animationBarActive4}></span>

            <div className={styles.linkButton} onMouseEnter={hoveringOn} onMouseLeave={hoveringOff}><Link to={props.destination} className={linkText}>{props.children}</Link></div>
        </div>

    
    );
}

export default LinkButton;