import { useContext } from "react";
import authContext from '../store/authContext';


export const useLoginCheck = () => {

    const API_URL = process.env.REACT_APP_API_URL;
    
  const authCtx = useContext(authContext);
    if (localStorage['sessionData']) {
    
    const decodedStorage = JSON.parse(localStorage.getItem('sessionData'));
    const date = new Date();
    const fetchedId = decodedStorage.id;
    const fetchedAccessToken = decodedStorage.token;
    const fetchedExpiry= date.getTime();
    

 fetch(`${API_URL}/controller/session.php?sessionid=${fetchedId}&expiry=${fetchedExpiry}`, {
    method: 'PATCH',
        headers: {
            'Authorization': fetchedAccessToken
        }
      })
      .then(response => {
        if (response.ok) {
           return response.json()}
           else {
            throw new Error('Error checking login status');
          }})
      .then(allData => {
        if(allData.messages[0] !== 'Token succesfully checked') {
          authCtx.logout();
        }
        else authCtx.login(fetchedId, fetchedAccessToken, 1200);
       }).catch(error => console.log(error));

      } else return;
}