import styles from './whiteLineSeparator.module.css';

const WhiteLineSeparator = (props) => {
    return (
        <div style={{width: '100%'}}>
                <hr style={{width: props.width, top: props.top}} className={styles.separation_line_horizontal}/>
            </div>
    )
}

export default WhiteLineSeparator;
