import styles from './singleNewsToDelete.module.css';

const SingleNewsToDelete = (props) => {
    return (
        <div className={styles.news}>
            <p className={styles.titel}>{props.titel}</p>
        </div>
    )
}

export default SingleNewsToDelete;