import React, { useState } from 'react';
import MainNav from './nav/MainNav';
import SocialNav from './nav/SocialNav';
import { CSSTransition } from 'react-transition-group';
import './scrollHeader.css';

const ScrollHeader = (props) => {

    const [scrollYps, setScrollYps] = useState();

    const isIn = scrollYps>400 ? true : false;
    window.addEventListener('scroll', ()=>{setScrollYps(window.scrollY)});

    return(
        <CSSTransition in={isIn} timeout={300} classNames='scrollHeader' mountOnEnter unmountOnExit>
        <header classNames='scrollHeader'>
            <div className='title'>
            <h1 className='logo_Francesco'>Francesco</h1>
            <h1 className='logo_Savignano'>Savignano</h1>
            </div>
            <div className='navigations'>
            <MainNav width="40vw"/>
            <SocialNav />
            </div>
            
            </header>
      </CSSTransition>
    );
}

export default ScrollHeader;