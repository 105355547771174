import { NavLink } from "react-router-dom";
import { useState } from "react";
import styles from './mainNav.module.css';

const MainNav = (props) => {

    const [menuHover, setMenuHover] = useState(false);
    const [linkHover, setLinkHover] = useState(0);

    const pallinaStyles = (menuHover ? styles.pallinaMenuHover : styles.pallinaMenuOut) + ' ' +
        (linkHover === 1 ? styles.pallinaLinkHover1 : '') +
        (linkHover === 2 ? styles.pallinaLinkHover2 : '') + 
        (linkHover === 3 ? styles.pallinaLinkHover3 : '') +
        (linkHover === 4 ? styles.pallinaLinkHover4 : '');

    return (
        <nav>
            <ul className = {styles.mainNav} style={{width: props.width}} 
             onMouseLeave={() => {setMenuHover(false); setLinkHover(0)} }
            >
                <li onMouseEnter = {()=>{setLinkHover(1)}} onClick= {() => {window.scroll(0,0); setLinkHover(0)}} >
                    <NavLink to = 'home' 
                    className={
                        props.activeSite === 'home' ? styles.mainLinkActive : styles.mainLink
                        }>
                            Home
                    </NavLink>
                </li>
                <li onMouseEnter = {()=>{setLinkHover(2)}} onClick= {() => {window.scroll(0,0); setLinkHover(0)}} >
                    <NavLink to = 'bio' className={
                        props.activeSite === 'bio' ? styles.mainLinkActive : styles.mainLink
                        }>Bio</NavLink></li>
                <li onMouseEnter = {()=>{setLinkHover(3)}} onClick= {() => {window.scroll(0,0); setLinkHover(0)}}>
                    <NavLink to = 'concerts' 
                    className={
                        props.activeSite === 'concerts' ? styles.mainLinkActive : styles.mainLink
                        }>Konzerte</NavLink></li>
                <li onMouseEnter = {()=>{setLinkHover(4)}} onClick= {() => {window.scroll(0,0); setLinkHover(0)}}>
                    <NavLink to = 'media' 
                    className={
                        props.activeSite === 'media' ? styles.mainLinkActive : styles.mainLink
                        }>Media</NavLink></li>
                
                <span className={pallinaStyles}></span>
            </ul>
            
        </nav>
    );
}

export default MainNav;