import React, {useState} from "react";

const dataContext = React.createContext({
    wasAlreadyVisited: false,
    projects: [{}],
    news:[{}],
    oldConcerts: [{}],
    newConcerts: [{}],
    getNewProjects: () => {},
    getNews: () => {},
    getOldconcerts: () => {},
    getNewConcerts: () => {},
    setVisited: () => {}
});

export const DataContextProvider = (props) => {
    const [wasAlreadyVisited, setWasAlreadyVisited] = useState(false);
    const [oldConcerts, setOldConcerts] = useState([{}]);
    const [newConcerts, setNewConcerts] = useState([{}]);
    const [news, setNews] = useState([{}]);
    const [projects, setProjects] = useState([{}]);


    const getOldConcerts = concerts => {
        setOldConcerts(concerts);
        };
        
    const getNewConcerts = concerts => {
        setNewConcerts(concerts);
    };

    const getNews = news => {
        setNews(news);
    };

    const getProjects = project => {
        setProjects(project);
    }

    const setVisited = () => {
        setWasAlreadyVisited(true);
    }

    const contextValue = {
        wasAlreadyVisited,
        projects,
        news,
        oldConcerts,
        newConcerts,
        getProjects,
        getNews,
        getOldConcerts,
        getNewConcerts,
        setVisited
    }

    return <dataContext.Provider value = {contextValue}>{props.children}</dataContext.Provider>
}

export default dataContext;