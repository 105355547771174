import styles from './singleConcertToDelete.module.css';

const SingleConcertToDelete = (props) => {
    return (
        <div className={styles.concert}>
            
            <h5 className={styles.datum}>{props.datum}</h5>
            <p className={styles.titel}>{props.titel}</p>
        </div>
    )
}

export default SingleConcertToDelete;