import React, {Fragment, useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollHeader from '../components/ScrollHeader';
import { Outlet } from 'react-router-dom';
import styles from './site.module.css';


const Site = (props) => {

    const [scrollY, setScrollY] = useState(window.scrollY);

    useEffect(() => {
        const handleScroll = event => {
          setScrollY(window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return(
    <div className={styles.container}>
        {props.viewport > 680 && <ScrollHeader/>}
        <Header activeSite={props.activeSite} viewport = {props.viewport} scrollY={scrollY}/>
        <Outlet/>
        <Footer viewport = {props.viewport}/>
    </div>
    );
};

export default Site;