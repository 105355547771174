import styles from './quote.module.css';
import leftQuote from '../img/left_quote_blue.svg';
import rightQuote from '../img/right_quote_blue.svg';

const Quote = (props) => {
    return (
        <blockquote className={styles.quote} style={{width: props.width, margin: props.margin}}>

                <h2 className={styles.content} style={{fontSize: props.fontSize}}>
                    <img src={leftQuote} className={styles.leftQuote} style={{width:props.quoteWidth}}/>
                    {props.quote}
                    <img src={rightQuote} className={styles.rightQuote} style={{width:props.quoteWidth}}/>
                </h2>

            <h5 className={styles.author} style={{fontSize: props.fontSize}}>{`- ${props.author}`}</h5>

        </blockquote>
    )
}

export default Quote;