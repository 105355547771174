import './App.css';
import { useState, lazy, Suspense } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from './pages/Home';
import Bio from './pages/Bio';
import Site from './pages/Site';
import Concerts from './pages/Concerts';
import Admin from './pages/Admin';
import Pressetext from './pages/Pressetext';


function App() {

  const [viewport, setViewport] = useState(window.innerWidth);
  

  window.addEventListener('resize', () => {setViewport(window.innerWidth)} );

  const [activeSite, setActiveSite] = useState();

  const getActiveSite = (site) => {
    setActiveSite(site);
  }

  const Media = lazy(() => import('./pages/Media'));

  return (
    <div className="App">

      <Routes>
        <Route path='/' element={<Navigate to='/site/home' />} />
        <Route path='/site' element = {<Site viewport = {viewport} activeSite={activeSite}/>}>
            <Route path = 'home' element = {<Home viewport = {viewport} onSiteLoad = {getActiveSite}/>} />
            <Route path = 'bio' element = {<Bio viewport = {viewport} onSiteLoad = {getActiveSite} />} />
            <Route path = 'concerts' element = {<Concerts viewport = {viewport} onSiteLoad = {getActiveSite}/>} />
            <Route path = 'media' element = {<Suspense><Media viewport = {viewport} onSiteLoad = {getActiveSite}/> </Suspense>} />
            <Route path = 'pressetext' element = {<Pressetext />} />
            <Route path = 'admin' element = {<Admin />} />
        </Route>
      </Routes>
      
    </div>
  );
}

export default App;
