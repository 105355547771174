import React, {Fragment} from 'react';
import styles from './pressetext.module.css';



const Pressetext = () => {
    return(
    <Fragment>
       <main><p>Das ist Pressetext</p></main>
    </Fragment>);
};

export default Pressetext;