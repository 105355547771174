import React from 'react';
import ReactDOM from 'react-dom/client';
import { DataContextProvider } from './store/dataContext';
import { AuthContextProvider } from './store/authContext';

import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
  <DataContextProvider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </DataContextProvider>
  </AuthContextProvider>
);


