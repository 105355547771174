import SingleNewsToDelete from "../SingleNewsToDelete";
import BlueBox from "../BlueBox";
import CloseIcon from '@mui/icons-material/Close';
import GoldLineSeparator from '../UI/GoldLineSeparator'
import BaroqueSeparator from '../UI/BaroqueSeparator';
import Button from "../UI/Button";

import { useEffect, useState, useContext} from "react";
import dataContext from '../../store/dataContext';
import styles from './deleteNewsForm.module.css';


const DeleteNewsForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const [formResponse, setFormResponse] = useState('');
    const dataCtx = useContext(dataContext);


    const getAllNews = () => { fetch(`${API_URL}/controller/newsTasks.php`, {
        method: 'GET'
          })
          .then(response => response.json())
          .then(allData => {
            if(allData) {
              dataCtx.getNews(allData.data);
            }
           })};

 
    useEffect(() => {
        
    getAllNews();

    }, []);
    

        const onNewsDeleteHandler = (id) => {
        const body = "newsid=" + id;
        fetch (`${API_URL}/controller/newsTasks.php?${body}`,
        {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then(response => {
            if(response.ok) return response.json();
            else throw new Error(response.messages[0])})
        .then(allData => {setFormResponse(allData.messages[0]); getAllNews()})
        .catch(error => setFormResponse(error))
            
    }


    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

        
              

        <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

        <BlueBox padding='40px' width= '60%' height= '80%'  overflow='scroll'>
                <h2 className={styles.titel}>News löschen</h2>
                <GoldLineSeparator />

                <BaroqueSeparator />
            

                {dataCtx.news.map(
                    news => { 
                        return(<div className={styles.singleConcertContainer}>
                            <SingleNewsToDelete 
                                    key = {Math.random()}
                                    titel={news.newsTitle}/>
                                    <button key={Math.random()} className={styles.button} onClick = {() => {onNewsDeleteHandler(news.id)}}>Löschen</button>
                        </div>
                                )
                            }
                        )
                    }

                   

                <Button onClickFunction = {props.onModalClose}>Schließen</Button>

        </BlueBox>


        
            <p className={styles.response}>{formResponse}</p>
        
        
    </div>
    )
}

export default DeleteNewsForm;