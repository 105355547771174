import React, {useState} from "react";

const authContext = React.createContext({});

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [accessToken, setAccessToken] = useState('');
    const [accessTokenExpiry, setAccessTokenExpiry] = useState(0);


    const login = (id, token, expiry) => {
        setSessionId(id);
        setAccessToken(token);
        setAccessTokenExpiry(expiry);        

        const now = new Date();
	    const item = {
        id,
		token,
		expiry: now.getTime() + 1200000,
	        }
	    localStorage.setItem('sessionData', JSON.stringify(item));
        setIsLoggedIn(true);
    }

    const logout = () => {
        localStorage.clear();
        setAccessToken('');
        setSessionId(null);
        setAccessTokenExpiry(0);
        setIsLoggedIn(false);
}

    const contextValue = {
        sessionId,
        isLoggedIn,
        accessToken,
        accessTokenExpiry,
        login,
        logout
    }
    return <authContext.Provider value = {contextValue}>{props.children}</authContext.Provider>
}

export default authContext;