import styles from './singleProjectToDelete.module.css';

const SingleProjectToDelete = (props) => {
    return (
        <div className={styles.news}>
            <p className={styles.titel}>{props.titel}</p>
        </div>
    )
}

export default SingleProjectToDelete;