import React, { useState, useEffect } from 'react';
import styles from './welcome.module.css';
import Button from './UI/Button';
import entryImage from '../img/savignano-entrypage.jpg';

const Welcome = (props) => {
    const [bgImageLoaded, setBgImageLoaded] = useState(false);

    useEffect(() => {
        let entryImg = new Image();
        entryImg.src = entryImage;
        entryImg.onload = () => setBgImageLoaded(true);
    }, []);

    return (
        <div className={`${!props.isOn ? styles.entrypage : styles.entrypageClosing} ${bgImageLoaded ? styles.bgImage : ''}`}>
            <div className={styles.title}>
                <h1 className={styles.logo_Francesco}>Francesco</h1>
                <h1 className={styles.logo_Savignano}>Savignano</h1>
            </div>
            <div className={styles.buttonContainer}>
                <Button width="180px" onClickFunction={props.onModalClose}>Seite betreten</Button>
            </div>
        </div>
    );
}

export default Welcome;
