import {useState, Fragment} from 'react';
import ReactDOM from 'react-dom';
import styles from './subNav.module.css';

import Backdrop from "../Backdrop";
import ContactForm from '../ContactForm';
import LinksModal from '../LinksModal';
import ImpressumModal from '../ImpressumModal';

const SubNav = (props) => {

    const backdropPortal = document.getElementById('backdrop');

    const modal = document.getElementById('modal');

    const [KontaktIsOn, setKontaktIsOn] = useState(false);
    const [LinksIsOn, setLinksIsOn] = useState(false);
    const [ImpressumIsOn, setImpressumIsOn] = useState(false);
    const [isClosing, setIsClosing] = useState(false);




    const onKontaktClickHandler = () => {
        setKontaktIsOn(true);
        setIsClosing(true);
    }

    const onCloseHandler = () => {
        setIsClosing(false);
        setTimeout(reallyCloseNow, 501);
    }

    const onLinksClickHandler = () => {
        setLinksIsOn(true);
        setIsClosing(true);
    }

     const onImpressumClickHandler = () => {
        setImpressumIsOn(true);
        setIsClosing(true);
    }


    const reallyCloseNow = () => {
        if (KontaktIsOn) setKontaktIsOn(false);
        if (LinksIsOn) setLinksIsOn(false);
        if (ImpressumIsOn) setImpressumIsOn(false);
    }

    return (
        <Fragment>
            {(KontaktIsOn || LinksIsOn || ImpressumIsOn) && ReactDOM.createPortal(<Backdrop isOn={isClosing}/>, backdropPortal)}
            {KontaktIsOn && ReactDOM.createPortal(<ContactForm viewport = {props.viewport} isOn={isClosing} onModalClose={onCloseHandler}/>, modal)};
            {LinksIsOn && ReactDOM.createPortal(<LinksModal isOn={isClosing} onModalClose={onCloseHandler}/>, modal)};
            {ImpressumIsOn && ReactDOM.createPortal(<ImpressumModal isOn={isClosing} onModalClose={onCloseHandler}/>, modal)};
        <div className={styles.subNav_box}>
        <ul className={styles.subNav}>
            <li className={styles.list}><p className={styles.mainLink} onClick = {onImpressumClickHandler}>Impressum</p></li>
            <li className={styles.list}><p className={styles.mainLink} onClick = {onLinksClickHandler}>Links</p></li>
            <li className={styles.list}><p className={styles.mainLink} onClick = {onKontaktClickHandler}>Kontakt</p></li>
        </ul>
    </div>
    </Fragment>
    );
}

export default SubNav;