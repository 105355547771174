import { Fragment } from 'react';
import styles from './backdrop.module.css';

const Backdrop = (props) => {
    return (
        <Fragment>
        <div className={props.isOn ? styles.backdrop : styles.backdropClosing}></div>
        </Fragment>
    )
}

export default Backdrop;