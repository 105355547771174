import styles from './addProjectForm.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../UI/Button';

import { useRef, useState } from 'react';

const AddProjectForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const inputProjectTitleRef = useRef();
    const inputProjectSubtitleRef = useRef();
    const inputProjectTextRef = useRef();
    const inputImageFileRef = useRef();


    const [formResponse, setFormResponse] = useState('');
    const [imageFile, setImageFile] = useState();

    const onImageFileInputHandler = (event) => {
        setImageFile(event.target.files[0]);
        
    }


    const onSubmitHandler = (event) => {
        event.preventDefault();
     
        
        const projectTitle = inputProjectTitleRef.current.value;
        const projectSubtitle = inputProjectSubtitleRef.current.value;
        const projectText = inputProjectTextRef.current.value;
        console.log(projectTitle, projectSubtitle, projectText, imageFile);
        const formData = new FormData();
        formData.append('projectTitle', projectTitle);
        formData.append('projectSubtitle', projectSubtitle);
        formData.append('projectText', projectText);
        formData.append('photo', imageFile);


      

        fetch(`${API_URL}/controller/projectTasks.php`,
        {method: 'POST',
        body: formData
        })
        .then(response => {
            if(response.ok) return response.json()
            else throw new Error(response.json().messages[0]);})
        .then(responseData => {
            if(responseData) setFormResponse(responseData.messages[0]);
            })
        .catch(error =>console.log(error));
    }

    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

            <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

            <form className = {styles.form} onSubmit={onSubmitHandler}>
                <h1 className={styles.title}>Projekt hinzufügen</h1>
                <label htmlFor='projectTitle' className = {styles.label}>Projekt-Titel</label>
                <input ref={inputProjectTitleRef} name = 'projectTitle' type='text' className = {styles.input} /><label htmlFor='projectSubtitle' className = {styles.label}>Projekt-Untertitel</label>
                <input ref={inputProjectSubtitleRef} name = 'projectSubtitle' type='text' className = {styles.input} />
                <label htmlFor='projectText' className = {styles.label}>Project-Text</label>
                <textarea ref={inputProjectTextRef} name='projectText' className={styles.message}></textarea>
                <label htmlFor='image' className = {styles.label}>Bild-Datei</label>
                <input ref={inputImageFileRef} onChange = {onImageFileInputHandler} name='image' type='file' className = {styles.fileUpload} accept="image/png, image/jpg, image/gif, image/jpeg" required />
                <div className={styles.buttons}><Button buttonType="submit">Posten</Button>
                <Button onClickFunction={props.onModalClose}>Schließen</Button>
                </div>
                <p className='white'>{formResponse}</p>
            </form>
            
        </div>
    )
}

export default AddProjectForm;