import { SpaceBar } from '@mui/icons-material';
import styles from './blueBox.module.css';

const BlueBox = (props) => {
    return (
        <article className={styles.blueBox} 
        style={{
            height:props.height, 
            width: props.width,
            display: props.display,
            flexDirection: props.flexDirection,
            justifyContent: props.justifyContent,
            alignItems: props.alignItems,
            gridColumn: props.span, 
            padding: props.padding, 
            margin: props.margin,
            overflow: props.overflow,
            flexWrap: props.flewWrap,
            position: props.position,
            zIndex: props.zIndex
            
            }}>
            {props.children}
        </article>
    );
}

export default BlueBox;