import styles from './addTerminForm.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../UI/Button';

import { useRef, useState } from 'react';

const AddTerminForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;
    
    const inputDatumRef = useRef();
    const inputOrtRef = useRef();
    const inputEnsembleRef = useRef();
    const inputStadtRef = useRef();
    const inputTitelRef = useRef();


    const [formResponse, setFormResponse] = useState('');


    const onSubmitHandler = (event) => {
        event.preventDefault();
        const datum = inputDatumRef.current.value;
        console.log(datum);
        const ort = inputOrtRef.current.value;
        const ensemble = inputEnsembleRef.current.value;
        const stadt = inputStadtRef.current.value;
        const titel = inputTitelRef.current.value;

        const terminObject = {datum, ort, ensemble, stadt, titel};
        const body = JSON.stringify(terminObject);

        fetch('http://localhost/francesco_savignano/react/api/v1/controller/terminTasks.php',
        {method: 'POST',
        body: body,
        headers: {
            'Content-Type' : 'application/json'
        }
        })
        .then(response => {
            if(response.ok) return response.json()
            else throw new Error('Server-Verbindungsprobleme. Bitte später erneut versuchen!');})
        .then(responseData => {
            if(responseData) setFormResponse(responseData.messages[0]);
            })
        .catch(error =>setFormResponse(error));
    }

    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

            <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

            <form className = {styles.form} onSubmit={onSubmitHandler}>
                <h1 className={styles.title}>Termin hinzufügen</h1>
                <label htmlFor='datum' className = {styles.label}>Datum</label>
                <input ref={inputDatumRef} name = 'datum' type='date' className = {styles.input} />
                <label htmlFor='ort' className = {styles.label}>Spielstätte</label>
                <input ref={inputOrtRef} name = 'ort' type='text' className = {styles.input} />
                <label htmlFor='ensemble' className = {styles.label}>Ensemble/Solist</label>
                <input ref={inputEnsembleRef} name = 'ensemble' type='text' className = {styles.input} required />
                <label htmlFor='stadt' className = {styles.label}>Stadt</label>
                <input ref={inputStadtRef} name = 'stadt' type='text' className = {styles.input} required />
                <label htmlFor='titel' className = {styles.label}>Titel</label>
                <input ref={inputTitelRef} name = 'titel' type='text' className = {styles.input} required />
                <div className={styles.buttons}><Button buttonType="submit">Posten</Button>
                <Button onClickFunction={props.onModalClose}>Schließen</Button>
                </div>
                <p className='white'>{formResponse}</p>
            </form>
            
        </div>
    )
}

export default AddTerminForm;