import styles from './contactForm.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from './UI/Button';
import { useNavigate } from 'react-router-dom';
import authContext from '../store/authContext';

import { useRef, useContext } from 'react';

const LoginForm = (props) => {
    
    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(authContext);

    const inputUsernameRef = useRef();
    const inputPasswordRef = useRef();

    // const [formResponse, setFormResponse] = useState('');

    const navigate = useNavigate();


    const onSubmitHandler = (event) => {
        event.preventDefault();
        const username = inputUsernameRef.current.value;
        const password = inputPasswordRef.current.value;
        const body = JSON.stringify({username, password});

        

        fetch(`${API_URL}/controller/session.php`, {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
              })
              .then(response => {
                if (response.ok) {
                   return response.json()}})
              .then(allData => {
                if(allData.statusCode === 200) {
                  authCtx.login(allData.data.sessionId, allData.data.accesstoken, allData.data.accesstokenexpiryseconds);
                //   setFormResponse(authCtx.messages);
                }
             }).catch(() => navigate(-1)
                );
            
        }


    


    return (
        <div className = {styles.container} style = {{top: 0, left: 0}}>

            <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose}/>

            <form className = {styles.form} onSubmit={onSubmitHandler}>
                <h1 className={styles.title}>Login</h1>
                <label htmlFor='username' className = {styles.label}>Username</label>
                <input ref={inputUsernameRef} name = 'username' type='text' className = {styles.input} />
                <label htmlFor='password' className = {styles.label}>Passwort</label>
                <input ref={inputPasswordRef} name = 'password' type='password' className = {styles.input} />
                <div className={styles.buttons}><Button buttonType="submit">Absenden</Button>
                <Button onClickFunction={props.onModalClose}>Schließen</Button>
                </div>
                {/* <p className='white'>{formResponse}</p> */}
            </form>
            
        </div>
    )
}

export default LoginForm;